.mapContainer {
    flex: 1;
    height: calc(65vh - 50px);
}

.layersButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    background-color: rgb(255 255 255 / 0.9);
    backdrop-filter: blur(10px);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: var(--font-size);
}

.layersToggleOverlay {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: rgb(255 255 255 / 0.9);
    backdrop-filter: blur(10px);
    border: var(--input-border);
    border-radius: var(--border-radius);
    padding: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.3);
    z-index: 10;
}
