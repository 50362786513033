.slopeAnalysis {
    border-radius: 2px;
    border: 1px solid var(--primary-color);
    margin-top: -5px;
    padding-bottom: 5px;
}

.slopeAnalysisColorStops {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
}

.slopeAnalysisTitle {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    background-color: var(--primary-color);
    padding: 5px 10px;
    color: white;
}

.slopeAnalysisRow {
    margin-bottom: 5px;
    padding: 0 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slopeAnalysisCol {
    margin-bottom: 5px;
    padding: 0 10px;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.innerSlopeAnalysisRow {
    margin: 0;
    padding: 0;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.innerSlopeAnalysisCol {
    margin: 0;
    padding: 0 15px 0 0;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.slopeAnalysisButtons {
    position: relative;
    padding: 0 10px;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: -15px;
}

.interpolateRow {
    padding: 0 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
}

.slopeAnalysisLabel {
    font-size: 10px;
    min-width: 70px;
    flex: 2;
}

.slopeInput {
    margin-top: -85px;
    max-width: 70px;
}

.colorSquare {
    width: 20px;
    height: 80px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 2px;
    border: 1px solid rgb(50 50 50 / 0.25);
}

.interpolateSlopeCheckBox {
    margin: 0;
    height: 20px;
    width: 20px;
}

.interpolateLabel {
    font-size: 12px;
    margin-left: 8px;
}

.interpolateTooltip {
    max-width: 250px;
}

.errorMessage {
    padding: 0 10px;
    font-size: 14px;
    color: rgb(150 0 0);
}

.removeColorStop,
.addColorStop {
    padding: 2px;
    margin: 0 4px 0 -4px;
}

.removeColorStop {
    border: none;
    color: rgb(50 50 50);
    opacity: 0.5;
    margin-left: 4px;
    flex: 1;
}

.removeColorStop:hover {
    color: rgb(50 50 50);
    opacity: 1;
}

.addColorStop {
    border-radius: 400px;
    border-style: dashed;
    opacity: 0.5;
    position: absolute;
    margin-top: 34px;
    margin-left: -4px;
}

.addColorStop:hover {
    border-style: solid;
    opacity: 1;
}

.removeColorStop > * {
    margin: 0;
}

.addColorStop > * {
    margin: 0;
}

.recolorButton,
.colorRangeDropdown {
    margin: 0 10px 10px;
}

.colorRangeLabel {
    margin-left: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 12px;
}
