.container {
    border-radius: 2px;
    font-size: 14px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    user-select: none;
}

.container:hover {
    opacity: 0.5;
}

.isPrimary {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: white;
}

.isSecondary {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: white;
}

.isSmall {
    font-size: 12px;
    padding: 5px 10px;
}

.isActive {
    opacity: 0.25;
}

.isDisabled {
    user-select: none;
    opacity: 0.5;
}

.isDisabled:hover {
    opacity: 0.5;
    cursor: default;
}

.isDestructive.isPrimary {
    border-color: #c21e4a;
    background-color: #c21e4a;
}

.isDestructive.isSecondary {
    border-color: #c21e4a;
    color: #c21e4a;
}

.icon {
    margin-right: 10px;
}
