@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

body {
    margin: 0;
    font-family: Poppins, sans-serif;
}

:root {
    --primary-color: #15358e;
}
