/* Grid */

.grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 50px 230px 2fr;
    grid-gap: 20px;
    grid-template-areas:
        "title title"
        "dsmMetaData slopeAnalysis"
        "mapComponent slopeAnalysis";
}

.uploadGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-right: 10px;
    grid-template-areas: "rgb-imagery slope";
}

.optionsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-right: 10px;
    grid-template-areas: "download reprocess archive";
}

.title {
    grid-area: title;
    text-align: center;
}

.dsmMetaData {
    overflow: auto;
    grid-area: dsmMetaData;
    width: 100%;
    font-size: 100%;

    /* white-space: nowrap; */
}

.slopeAnalysis {
    grid-area: slopeAnalysis;
}

.dsmDetailParagraph {
    margin-top: 0;
    margin-bottom: 5px;
}

.mapComponent {
    grid-area: mapComponent;
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.downloadButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    grid-area: "download";
    margin-top: 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.uploadSlope {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    grid-area: "slope";
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

.uploadSlope.loading {
    background-color: #007bff;
    cursor: default;
    position: relative;
}

.uploadSlope.loading::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -15px;
    margin-left: -10px;
    border: 4px solid rgb(255 255 255 / 0.6);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.uploadRGB {
    background-image: linear-gradient(
        to right,
        hsl(0deg 60% 45%),
        hsl(39deg 60% 45%),
        hsl(60deg 60% 45%),
        hsl(120deg 60% 45%),
        hsl(240deg 60% 45%),
        hsl(275deg 60% 45%),
        hsl(300deg 60% 45%),
        hsl(275deg 60% 45%),
        hsl(240deg 60% 45%),
        hsl(120deg 60% 45%),
        hsl(60deg 60% 45%),
        hsl(39deg 60% 45%),
        hsl(0deg 60% 45%)
    );
    background-size: 400% 100%;
    background-position: -60% 50%;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    grid-area: "rgb-imagery";
    margin-bottom: 10px;
    transition: background-color 0.3s ease;

    &:hover {
        cursor: pointer;
    }
}

@keyframes rainbow-animation {
    0% {
        background-position: -100% 50%;
    }

    100% {
        background-position: 33% 50%;
    }
}

.uploadRGB.loading {
    animation: rainbow-animation 3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.reprocessButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-area: "reprocess";
    justify-content: space-between;
    width: auto;
    min-width: 100px;
    transition: width 0.3s;
    padding-right: 10px;
    position: relative;

    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #88c2ff; /* A lightened version of the button color */
        color: #373737; /* A lightened version of the text color */
        cursor: not-allowed; /* Indicates the button is not clickable */
        opacity: 0.7; /* Makes the button slightly transparent */
    }

    &:hover:disabled {
        background-color: #88c2ff; /* Keep the same lightened version even on hover */
    }
}

.toggleSlope {
    width: 20px;
    height: 20px;
    margin-bottom: 15px;
}

.archiveButton {
    color: white;
    z-index: 10;
    background-color: #a94442;
    border: none;
    font-weight: bold;
    margin-top: 10px;
    font-size: 12;
    grid-area: "archive";
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration);
    margin-bottom: 10px;
}

.h2 {
    font-size: 24px;
    color: #495057;
}

.p {
    font-size: var(--font-size);
    color: #6c757d;
    line-height: 1.5;
}

.dsmDetailTable {
    border-collapse: collapse;
    margin-top: 10px;
}

.dsmDetailTable td {
    padding: 8px;
    border: 1px solid #ddd;
    word-break: break-all;
}

.dsmDetailTable tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.noSelectedDSM {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
