.background {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.modal {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: white;
    min-width: 260px;
    padding: 5px 0;
}

.option {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.isSmall .option {
    padding: 5px 10px;
    font-size: 12px;
}

.option:hover {
    background-color: #eee;
}

.option.isDisabled {
    cursor: default;
    opacity: 0.25;
}

.option.isDisabled:hover {
    background-color: unset;
}
