.input {
    font-size: 14px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    border-radius: 2px;
    font-family: Poppins, sans-serif;
    width: 100%;
    box-sizing: border-box;
}

.isSmall {
    font-size: 12px;
    padding: 5px 10px;
}

.input.error {
    border: 2px solid red;
}
