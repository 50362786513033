/* CSS Variables */
:root {
    --btn-padding: 10px 20px;
    --font-size: 16px;
    --border-radius: 4px;
    --transition-duration: 0.3s;
    --input-border: 1px solid #ccc;

    font-family: inherit;
}

/* Container */
.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* Content */
.content {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 70px auto;
    grid-gap: 20px;
    grid-template-areas:
        "upload dsmDetails"
        "dsmList dsmDetails";
}

.uploadButtons {
    display: flex;
}

.dialogTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.uploadDSMButton,
.reprocessAllButton,
.uploadButton,
.closeButton {
    padding: var(--btn-padding);
    font-size: var(--font-size);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.uploadDSMButton {
    margin: 10px;
    color: white;
    background-color: var(--primary-color);
    border: none;
    font-weight: bold;
}

.reprocessAllButton {
    margin: 5px;
    color: white;
    background-color: brown;
    border: none;
    font-weight: bold;
}

.row {
    display: flex;
    flex-direction: row;
}

.uploadButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
}

.closeButton {
    align-self: flex-end;
    background-color: #f44336;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
}

.uploadDSMButton:hover {
    opacity: 0.8;
}

.uploadDialog {
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.3);
    border-radius: 8px;
}

.uploadDialog form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.uploadDialog form input[type="file"],
.uploadDialog form input[type="text"] {
    padding: 10px;
    border-radius: var(--border-radius);
    border: var(--input-border);
}

.uploadDialog::backdrop {
    background-color: #15358e20;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.toggleContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    padding: 10px;
    border: var(--input-border);
    border-radius: var(--border-radius);
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.1);
}

.toggleContainer div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.toggleContainer label {
    cursor: pointer;
}

.slopeRange {
    padding-left: 15px;
}

.hiddenButton {
    display: none;
}

.colorSquare {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
}

.slopeAnalysisRow {
    margin-bottom: 5px;
    padding: 0 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.slopeInput {
    margin-left: 10px;
    max-width: 60px;
}

.interpolateSlopeCheckBox {
    margin-left: 10px;
}

.uploadDialogText {
    font-size: smaller;
    margin: 0;
}
