.dsmList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    border: var(--input-border);
    background-color: #f8f8f8;
    overflow-y: auto;
}

.dsmListItem {
    cursor: pointer;
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
}

.dsmListItem:last-child {
    border-bottom: none;
}

.spinner {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
