.container {
    margin-bottom: 10px;
}

.button {
    margin-bottom: 0;
}

.modal {
    width: 100%;
}

.modalContainer {
    position: relative;
}

.option {
    font-size: 14px;
}

.option.isSmall {
    font-size: 12px;
}
